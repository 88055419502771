import React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';

const PrivacyPolicy = () => (
  <>
    <Seo title="Privacy Notice and Cookie Policy" />
   
    <Layout>

      <section>

        <div className="section-container">

          <h1><span>Privacy Notice and Cookie Policy</span></h1>

          <p>When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies.</p>

          <p>This information might be about you, your preferences or your device, and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies.</p>

          <h2>Strictly necessary cookies</h2>

          <p>These cookies are necessary and always active for the website to function and cannot be switched off in our systems. They are usually set only in response to actions made by you requesting services such: as setting your privacy preferences, logging in or filling in forms.</p>

          <p>You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.</p>

          <p><strong>Cookies used:</strong><br/>
          Google Analytics<br />
          Formstack</p>

          <h2> Performance cookies </h2>

          <p>These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.</p>

          <p>All the information the cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.</p>

          <p><strong>Cookies used:</strong><br/>
          Google Analytics</p>

          <h2>Functional cookies</h2>

          <p>These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages.</p>

          <p>If you do not allow these cookies, some or all of these services may not function properly.</p>

          <p><strong>Cookies used:</strong><br/>
          vimeo.com</p>

          <h2>Targeting cookies</h2>

          <p>These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant ads on other sites.</p>

          <p>They do not store personal information but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less customized advertising.</p>

          <p><strong>Cookies used:</strong><br/>
          Amsive Digital</p>
        </div>
      </section>
   
    </Layout> 
  </>
);

export default PrivacyPolicy;
